import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/nurse-ndidi-retire/1.jpg';
import blogd2 from '../../assets/images/2022/nurse-ndidi-retire/2.jpg';
import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  NURSE NDIDI ENUWA RETIRES: SPONSORS FREE EYE SCREENING FOR
                  LUTH STAFF
                </h2>
                <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Hospital Management granted approval for the Sponsorship
                  of Free Eye Screening for members of Staff in celebration of
                  the retirement of Nurse Ndidi Enuwa after many years of
                  meritorious service to the Hospital.
                </p>
                <div className='row'>
                  <div className='col-lg-12' >
                    <img src={blogd2}  height={370} />
                  </div>
                  <div className='col-lg-12' >
                    <img src={blogd3} height={370}/>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Nurse Ndidi Enuwa Retires
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Sponsorship of the Free Eye Screening was through the
                  generosity of the Retiree who used the opportunity to thank
                  God as well as give back to humanity and society at large. The
                  Programme was held on Wednesday 13th July, 2022 at the LUTH
                  Ophthalmology Department popularly known as the Guinness Eye
                  Centre. Climax of the event include the following: • One (1)
                  Free Glaucoma Surgery • One (1) Free Cataract Surgery • Fifty
                  (50) pieces of Free Eye Glasses.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
